const initialState = {
    gridLogStatus: false,
    gridLog: [],
    metaLog: {
        page: 1,
        limit: 20,
        total: 0,
        totalPage: 0,
    }
};

export default function LogReducer(state = initialState, action) {
    switch (action.type) {
        case 'GRID_LOGS_STATUS_PROCESS':
            return {...state, gridLogStatus: true, gridLog: initialState.metaLog, metaLog: initialState.metaLog};
        case 'GRID_LOGS_STATUS_SUCCESS':
            return {...state, gridLogStatus: false, gridLog: action.payload.logs, metaLog: action.payload.meta};
        case 'GRID_LOGS_STATUS_FAILURE':
            return {...state, gridLogStatus: false, gridLog: initialState.metaLog, metaLog: initialState.metaLog};

        default:
            return state;
    }

}