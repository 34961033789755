const meta = {
    page: 1,
    limit: 20,
    total: 0,
    totalPage: 0,
};

const initialState = {
    activeEventStatus: false,
    activeEvent: [],
    gridEventStatus: false,
    gridEvent: [],
    metaEvent: {
        page: 1,
        limit: 20,
        total: 0,
        totalPage: 0,
    },
    formEventProcess: false,
    formEventErrors: {},
    formEvent: {
        id: null,
        title: null,
        startTime: null,
        endTime: null,
    },
    deleteEventProcess: false,
    viewEventStatus: false,
    viewEvent: {
        id: null,
        title: null,
        startTime: null,
        endTime: null,
    },


    gridEventRelationStatus: {},
    gridEventRelation: {},
    metaEventRelation: {},
    formEventRelationProcess: false,
    formEventRelationSection: null,
    formEventRelation: {id: null},
    deleteEventRelationProcess: false,
    viewEventRelationStatus: {},
    viewEventRelation: {},


    gridEventRsvpStatus: false,
    gridEventRsvp: [],
    metaEventRsvp: {
        page: 1,
        limit: 20,
        total: 0,
        totalPage: 0,
    },
    formEventRsvpProcess: false,
    formEventRsvp: [],

    statEventRsvpStatus: false,
    statEventRsvp: [],

    openingProgress: null,

};

export default function EventReducer(state = initialState, action) {
    switch (action.type) {
        case 'GRID_EVENT_STATUS_PROCESS':
            return {...state, gridEventStatus: true, gridEvent: initialState.gridEvent, metaEvent: initialState.metaEvent};
        case 'GRID_EVENT_STATUS_SUCCESS':
            return {...state, gridEventStatus: false, gridEvent: action.payload.events, metaEvent: action.payload.meta};
        case 'GRID_EVENT_STATUS_FAILURE':
            return {...state, gridEventStatus: false, gridEvent: initialState.gridEvent, metaEvent: initialState.metaEvent};

        case 'ACTIVE_EVENT_STATUS_PROCESS':
            return {...state, activeEventStatus: true, activeEvent: action.payload.events};
        case 'ACTIVE_EVENT_STATUS_SUCCESS':
            return {...state, activeEventStatus: false, activeEvent: action.payload.events};
        case 'ACTIVE_EVENT_STATUS_FAILURE':
            return {...state, activeEventStatus: false, activeEvent: []};

        case 'VIEW_EVENT_STATUS_PROCESS':
            return {...state, viewEventStatus: true, viewEvent: initialState.viewEvent};
        case 'VIEW_EVENT_STATUS_SUCCESS':
            return {...state, viewEventStatus: false, viewEvent: action.payload.events};
        case 'VIEW_EVENT_STATUS_FAILURE':
            return {...state, viewEventStatus: false, viewEvent: initialState.viewEvent};

        case 'FORM_EVENT_PROCESS':
            return {...state, formEventProcess: true};
        case 'FORM_EVENT_SUCCESS':
            return {...state, formEventProcess: false, formEvent: action.payload.events};
        case 'FORM_EVENT_FAILURE':
            return {...state, formEventProcess: false};

        case 'DELETE_EVENT_PROCESS':
            return {...state, deleteEventProcess: true};
        case 'DELETE_EVENT_SUCCESS':
            return {...state, deleteEventProcess: false};
        case 'DELETE_EVENT_FAILURE':
            return {...state, deleteEventProcess: false};

        case 'FORM_EVENT_RELATION_PROCESS':
            return {...state, formEventRelationProcess: true, formEventRelationSection: action.relation};
        case 'FORM_EVENT_RELATION_SUCCESS':
            return {...state, formEventRelationProcess: false, formEventRelationSection: action.relation, formEventRelation: action.payload};
        case 'FORM_EVENT_RELATION_FAILURE':
            return {...state, formEventRelationProcess: false, formEventRelationSection: action.relation};

        case 'DELETE_EVENT_RELATION_PROCESS':
            return {...state, deleteEventRelationProcess: true};
        case 'DELETE_EVENT_RELATION_SUCCESS':
            return {...state, deleteEventRelationProcess: false};
        case 'DELETE_EVENT_RELATION_FAILURE':
            return {...state, deleteEventRelationProcess: false};

        case 'GRID_EVENT_RELATION_STATUS_PROCESS_ACTIVITY':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, activity: true}, gridEventRelation: {...state.gridEventRelation, activity: []}, metaEventRelation: {...state.metaEventRelation, activity: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_ACTIVITY':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, activity: false}, gridEventRelation: {...state.gridEventRelation, activity: action.payload.logs}, metaEventRelation: {...state.metaEventRelation, activity: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_ACTIVITY':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, activity: false}, gridEventRelation: {...state.gridEventRelation, activity: []}, metaEventRelation: {...state.metaEventRelation, activity: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_MENU':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, menu: true}, gridEventRelation: {...state.gridEventRelation, menu: []}, metaEventRelation: {...state.metaEventRelation, menu: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_MENU':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, menu: false}, gridEventRelation: {...state.gridEventRelation, menu: action.payload.menus}, metaEventRelation: {...state.metaEventRelation, menu: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_MENU':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, menu: false}, gridEventRelation: {...state.gridEventRelation, menu: []}, metaEventRelation: {...state.metaEventRelation, menu: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_BANNER':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, banner: true}, gridEventRelation: {...state.gridEventRelation, banner: []}, metaEventRelation: {...state.metaEventRelation, banner: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_BANNER':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, banner: false}, gridEventRelation: {...state.gridEventRelation, banner: action.payload.splashs}, metaEventRelation: {...state.metaEventRelation, banner: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_BANNER':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, banner: false}, gridEventRelation: {...state.gridEventRelation, banner: []}, metaEventRelation: {...state.metaEventRelation, banner: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_PIC':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, pic: true}, gridEventRelation: {...state.gridEventRelation, pic: []}, metaEventRelation: {...state.metaEventRelation, pic: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_PIC':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, pic: false}, gridEventRelation: {...state.gridEventRelation, pic: action.payload.pics}, metaEventRelation: {...state.metaEventRelation, pic: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_PIC':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, pic: false}, gridEventRelation: {...state.gridEventRelation, pic: []}, metaEventRelation: {...state.metaEventRelation, pic: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_POLL':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, poll: true}, gridEventRelation: {...state.gridEventRelation, poll: []}, metaEventRelation: {...state.metaEventRelation, poll: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_POLL':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, poll: false}, gridEventRelation: {...state.gridEventRelation, poll: action.payload.polls}, metaEventRelation: {...state.metaEventRelation, poll: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_POLL':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, poll: false}, gridEventRelation: {...state.gridEventRelation, poll: []}, metaEventRelation: {...state.metaEventRelation, poll: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_SOUVENIR':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, souvenir: true}, gridEventRelation: {...state.gridEventRelation, souvenir: []}, metaEventRelation: {...state.metaEventRelation, souvenir: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_SOUVENIR':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, souvenir: false}, gridEventRelation: {...state.gridEventRelation, souvenir: action.payload.souvenirs}, metaEventRelation: {...state.metaEventRelation, souvenir: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_SOUVENIR':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, souvenir: false}, gridEventRelation: {...state.gridEventRelation, souvenir: []}, metaEventRelation: {...state.metaEventRelation, souvenir: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_PAGE':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, page: true}, gridEventRelation: {...state.gridEventRelation, page: []}, metaEventRelation: {...state.metaEventRelation, page: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_PAGE':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, page: false}, gridEventRelation: {...state.gridEventRelation, page: action.payload.pages}, metaEventRelation: {...state.metaEventRelation, page: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_PAGE':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, page: false}, gridEventRelation: {...state.gridEventRelation, page: []}, metaEventRelation: {...state.metaEventRelation, page: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_MULTIMEDIA':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, multimedia: true}, gridEventRelation: {...state.gridEventRelation, multimedia: []}, metaEventRelation: {...state.metaEventRelation, multimedia: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_MULTIMEDIA':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, multimedia: false}, gridEventRelation: {...state.gridEventRelation, multimedia: action.payload.multimedias}, metaEventRelation: {...state.metaEventRelation, multimedia: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_MULTIMEDIA':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, multimedia: false}, gridEventRelation: {...state.gridEventRelation, multimedia: []}, metaEventRelation: {...state.metaEventRelation, multimedia: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_TEAM':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, team: true}, gridEventRelation: {...state.gridEventRelation, team: []}, metaEventRelation: {...state.metaEventRelation, team: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_TEAM':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, team: false}, gridEventRelation: {...state.gridEventRelation, team: action.payload.teams}, metaEventRelation: {...state.metaEventRelation, team: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_TEAM':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, team: false}, gridEventRelation: {...state.gridEventRelation, team: []}, metaEventRelation: {...state.metaEventRelation, team: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_RSVP':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, rsvp: true}, gridEventRelation: {...state.gridEventRelation, rsvp: []}, metaEventRelation: {...state.metaEventRelation, rsvp: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_RSVP':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, rsvp: false}, gridEventRelation: {...state.gridEventRelation, rsvp: action.payload.rsvp}, metaEventRelation: {...state.metaEventRelation, rsvp: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_RSVP':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, rsvp: false}, gridEventRelation: {...state.gridEventRelation, rsvp: []}, metaEventRelation: {...state.metaEventRelation, rsvp: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_QUIZ':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, quiz: true}, gridEventRelation: {...state.gridEventRelation, quiz: []}, metaEventRelation: {...state.metaEventRelation, quiz: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_QUIZ':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, quiz: false}, gridEventRelation: {...state.gridEventRelation, quiz: action.payload.quizzes}, metaEventRelation: {...state.metaEventRelation, quiz: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_QUIZ':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, quiz: false}, gridEventRelation: {...state.gridEventRelation, quiz: []}, metaEventRelation: {...state.metaEventRelation, quiz: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_QUIZCOLLECTION':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, quizCollection: true}, gridEventRelation: {...state.gridEventRelation, quizCollection: []}, metaEventRelation: {...state.metaEventRelation, quizCollection: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_QUIZCOLLECTION':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, quizCollection: false}, gridEventRelation: {...state.gridEventRelation, quizCollection: action.payload.quizzes}, metaEventRelation: {...state.metaEventRelation, quizCollection: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_QUIZCOLLECTION':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, quizCollection: false}, gridEventRelation: {...state.gridEventRelation, quizCollection: []}, metaEventRelation: {...state.metaEventRelation, quizCollection: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_SCHEDULE':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, schedule: true}, gridEventRelation: {...state.gridEventRelation, schedule: []}, metaEventRelation: {...state.metaEventRelation, schedule: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_SCHEDULE':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, schedule: false}, gridEventRelation: {...state.gridEventRelation, schedule: action.payload.schedules}, metaEventRelation: {...state.metaEventRelation, schedule: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_SCHEDULE':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, schedule: false}, gridEventRelation: {...state.gridEventRelation, schedule: []}, metaEventRelation: {...state.metaEventRelation, schedule: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_BOOTH':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, booth: true}, gridEventRelation: {...state.gridEventRelation, booth: []}, metaEventRelation: {...state.metaEventRelation, booth: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_BOOTH':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, booth: false}, gridEventRelation: {...state.gridEventRelation, booth: action.payload.booths}, metaEventRelation: {...state.metaEventRelation, booth: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_BOOTH':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, booth: false}, gridEventRelation: {...state.gridEventRelation, booth: []}, metaEventRelation: {...state.metaEventRelation, booth: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_MERCHANT':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, merchant: true}, gridEventRelation: {...state.gridEventRelation, merchant: []}, metaEventRelation: {...state.metaEventRelation, merchant: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_MERCHANT':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, merchant: false}, gridEventRelation: {...state.gridEventRelation, merchant: action.payload.merchants}, metaEventRelation: {...state.metaEventRelation, merchant: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_MERCHANT':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, merchant: false}, gridEventRelation: {...state.gridEventRelation, merchant: []}, metaEventRelation: {...state.metaEventRelation, merchant: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_FORM':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, form: true}, gridEventRelation: {...state.gridEventRelation, form: []}, metaEventRelation: {...state.metaEventRelation, form: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_FORM':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, form: false}, gridEventRelation: {...state.gridEventRelation, form: action.payload.forms}, metaEventRelation: {...state.metaEventRelation, form: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_FORM':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, form: false}, gridEventRelation: {...state.gridEventRelation, form: []}, metaEventRelation: {...state.metaEventRelation, form: meta}};
        case 'GRID_EVENT_RELATION_STATUS_PROCESS_PORSENI':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, porseni: true}, gridEventRelation: {...state.gridEventRelation, porseni: []}, metaEventRelation: {...state.metaEventRelation, porseni: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_PORSENI':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, porseni: false}, gridEventRelation: {...state.gridEventRelation, porseni: action.payload.porsenis}, metaEventRelation: {...state.metaEventRelation, porseni: action.payload.meta}};
        case 'GRID_EVENT_RELATION_STATUS_FAILURE_PORSENI':
            return {...state, gridEventRelationStatus: {...state.gridEventRelationStatus, porseni: false}, gridEventRelation: {...state.gridEventRelation, porseni: []}, metaEventRelation: {...state.metaEventRelation, porseni: meta}};
        case 'GRID_EVENT_RELATION_STATUS_SUCCESS_OPENING':
            return {...state, openingProgress: action.payload[0]};

        case 'VIEW_EVENT_RELATION_STATUS_PROCESS_MENU':
            return {...state, viewEventRelationStatus: {...state.viewEventRelationStatus, menu: true}, viewEventRelation: {...state.viewEventRelation, menu: {}}};
        case 'VIEW_EVENT_RELATION_STATUS_SUCCESS_MENU':
            return {...state, viewEventRelationStatus: {...state.viewEventRelationStatus, menu: false}, viewEventRelation: {...state.viewEventRelation, menu: action.payload}};
        case 'VIEW_EVENT_RELATION_STATUS_FAILURE_MENU':
            return {...state, viewEventRelationStatus: {...state.viewEventRelationStatus, menu: false}, viewEventRelation: {...state.viewEventRelation, menu: {}}};

        case 'GRID_EVENT_RSVP_STATUS_PROCESS':
            return {...state, gridEventRsvpStatus: true, gridEventRsvp: initialState.gridEventRsvp, metaEventRsvp: initialState.metaEventRsvp};
        case 'GRID_EVENT_RSVP_STATUS_SUCCESS':
            return {...state, gridEventRsvpStatus: false, gridEventRsvp: action.payload.rsvp, metaEventRsvp: action.payload.meta};
        case 'GRID_EVENT_RSVP_STATUS_FAILURE':
            return {...state, gridEventRsvpStatus: false, gridEventRsvp: initialState.gridEventRsvp, metaEventRsvp: initialState.metaEventRsvp};

        case 'FORM_EVENT_RSVP_PROCESS':
            return {...state, formEventRsvpProcess: true};
        case 'FORM_EVENT_RSVP_SUCCESS':
            return {...state, formEventRsvpProcess: false, formEventRsvp: action.payload.rsvp};
        case 'FORM_EVENT_RSVP_FAILURE':
            return {...state, formEventRsvpProcess: false};

        case 'STAT_EVENT_RSVP_STATUS_PROCESS':
            return {...state, statEventRsvpStatus: true, statEventRsvp: initialState.statEventRsvp};
        case 'STAT_EVENT_RSVP_STATUS_SUCCESS':
            return {...state, statEventRsvpStatus: false, statEventRsvp: action.payload.events};
        case 'STAT_EVENT_RSVP_STATUS_FAILURE':
            return {...state, statEventRsvpStatus: false, statEventRsvp: initialState.statEventRsvp};

        default:
            return state;
    }

}
