const schedule = {
    id: null,
    title: null,
};
const meta = {
    page: 1,
    limit: 20,
    total: 0,
    totalPage: 0,
};

const initialState = {
    gridScheduleStatus: false,
    gridSchedule: [],
    metaSchedule: meta,
    viewScheduleStatus: false,
    viewSchedule: schedule,
    formScheduleProcess: false,
    formScheduleErrors: {},
    formSchedule: schedule,
    deleteScheduleProcess: false,

    gridScheduleRelationStatus: {},
    gridScheduleRelation: {},
    metaScheduleRelation: {},
    formScheduleRelationProcess: false,
    formScheduleRelationSection: null,
    formScheduleRelation: {id: null},
    deleteScheduleRelationProcess: false,
    viewScheduleRelationStatus: {},
    viewScheduleRelation: {},
};

export default function ScheduleReducer(state = initialState, action) {
    switch (action.type) {
        case 'GRID_SCHEDULE_STATUS_PROCESS':
            return {...state, gridScheduleStatus: true, gridSchedule: initialState.gridSchedule, metaSchedule: meta};
        case 'GRID_SCHEDULE_STATUS_SUCCESS':
            return {...state, gridScheduleStatus: false, gridSchedule: action.payload.schedules, metaSchedule: action.payload.meta};
        case 'GRID_SCHEDULE_STATUS_FAILURE':
            return {...state, gridScheduleStatus: false, gridSchedule: initialState.gridSchedule, metaSchedule: meta};

        case 'VIEW_SCHEDULE_STATUS_PROCESS':
            return {...state, viewScheduleStatus: true, viewSchedule: initialState.viewSchedule};
        case 'VIEW_SCHEDULE_STATUS_SUCCESS':
            return {...state, viewScheduleStatus: false, viewSchedule: action.payload};
        case 'VIEW_SCHEDULE_STATUS_FAILURE':
            return {...state, viewScheduleStatus: false, viewSchedule: initialState.viewSchedule};

        case 'FORM_SCHEDULE_PROCESS':
            return {...state, formScheduleProcess: true, formSchedule: initialState.formSchedule};
        case 'FORM_SCHEDULE_SUCCESS':
            return {...state, formScheduleProcess: false, formSchedule: action.payload};
        case 'FORM_SCHEDULE_FAILURE':
            return {...state, formScheduleProcess: false, formSchedule: initialState.formSchedule};

        case 'DELETE_SCHEDULE_PROCESS':
            return {...state, deleteScheduleProcess: true};
        case 'DELETE_SCHEDULE_SUCCESS':
            return {...state, deleteScheduleProcess: false};
        case 'DELETE_SCHEDULE_FAILURE':
            return {...state, deleteScheduleProcess: false};

        case 'VIEW_SCHEDULE_RELATION_STATUS_PROCESS_MENU':
            return {...state, viewScheduleRelationStatus: {...state.viewScheduleRelationStatus, menu: true}, viewScheduleRelation: {...state.viewScheduleRelation, menu: {}}};
        case 'VIEW_SCHEDULE_RELATION_STATUS_SUCCESS_MENU':
            return {...state, viewScheduleRelationStatus: {...state.viewScheduleRelationStatus, menu: false}, viewScheduleRelation: {...state.viewScheduleRelation, menu: action.payload}};
        case 'VIEW_SCHEDULE_RELATION_STATUS_FAILURE_MENU':
            return {...state, viewScheduleRelationStatus: {...state.viewScheduleRelationStatus, menu: false}, viewScheduleRelation: {...state.viewScheduleRelation, menu: {}}};
            
        case 'FORM_SCHEDULE_RELATION_PROCESS':
            return {...state, formScheduleRelationProcess: true, formScheduleRelationSection: action.relation};
        case 'FORM_SCHEDULE_RELATION_SUCCESS':
            return {...state, formScheduleRelationProcess: false, formScheduleRelationSection: action.relation, formScheduleRelation: action.payload};
        case 'FORM_SCHEDULE_RELATION_FAILURE':
            return {...state, formScheduleRelationProcess: false, formScheduleRelationSection: action.relation};

        case 'DELETE_SCHEDULE_RELATION_PROCESS':
            return {...state, deleteScheduleRelationProcess: true};
        case 'DELETE_SCHEDULE_RELATION_SUCCESS':
            return {...state, deleteScheduleRelationProcess: false};
        case 'DELETE_SCHEDULE_RELATION_FAILURE':
            return {...state, deleteScheduleRelationProcess: false};

        case 'GRID_SCHEDULE_RELATION_STATUS_PROCESS_ABSENT':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, absent: true}, gridScheduleRelation: {...state.gridScheduleRelation, absent: []}, metaScheduleRelation: {...state.metaScheduleRelation, absent: meta}};
        case 'GRID_SCHEDULE_RELATION_STATUS_SUCCESS_ABSENT':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, absent: false}, gridScheduleRelation: {...state.gridScheduleRelation, absent: action.payload.absents}, metaScheduleRelation: {...state.metaScheduleRelation, absent: action.payload.meta}};
        case 'GRID_SCHEDULE_RELATION_STATUS_FAILURE_ABSENT':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, absent: false}, gridScheduleRelation: {...state.gridScheduleRelation, absent: []}, metaScheduleRelation: {...state.metaScheduleRelation, absent: meta}};
        case 'GRID_SCHEDULE_RELATION_STATUS_PROCESS_RATING':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, rating: true}, gridScheduleRelation: {...state.gridScheduleRelation, rating: []}, metaScheduleRelation: {...state.metaScheduleRelation, rating: meta}};
        case 'GRID_SCHEDULE_RELATION_STATUS_SUCCESS_RATING':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, rating: false}, gridScheduleRelation: {...state.gridScheduleRelation, rating: action.payload.ratings}, metaScheduleRelation: {...state.metaScheduleRelation, rating: action.payload.meta}};
        case 'GRID_SCHEDULE_RELATION_STATUS_FAILURE_RATING':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, rating: false}, gridScheduleRelation: {...state.gridScheduleRelation, rating: []}, metaScheduleRelation: {...state.metaScheduleRelation, rating: meta}};
        case 'GRID_SCHEDULE_RELATION_STATUS_PROCESS_QUESTION':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, question: true}, gridScheduleRelation: {...state.gridScheduleRelation, question: []}, metaScheduleRelation: {...state.metaScheduleRelation, question: meta}};
        case 'GRID_SCHEDULE_RELATION_STATUS_SUCCESS_QUESTION':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, question: false}, gridScheduleRelation: {...state.gridScheduleRelation, question: action.payload.questions}, metaScheduleRelation: {...state.metaScheduleRelation, question: action.payload.meta}};
        case 'GRID_SCHEDULE_RELATION_STATUS_FAILURE_QUESTION':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, question: false}, gridScheduleRelation: {...state.gridScheduleRelation, question: []}, metaScheduleRelation: {...state.metaScheduleRelation, question: meta}};
        case 'GRID_SCHEDULE_QUESTION_REPLY_STATUS_PROCESS':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, reply: true}, gridScheduleRelation: {...state.gridScheduleRelation, reply: []}};
        case 'GRID_SCHEDULE_QUESTION_REPLY_STATUS_SUCCESS':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, reply: false}, gridScheduleRelation: {...state.gridScheduleRelation, reply: action.payload.replies}};
        case 'GRID_SCHEDULE_QUESTION_REPLY_STATUS_FAILURE':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, reply: false}, gridScheduleRelation: {...state.gridScheduleRelation, reply: []}};
        case 'GRID_SCHEDULE_RELATION_STATUS_PROCESS_RSVP':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, rsvp: true}, gridScheduleRelation: {...state.gridScheduleRelation, rsvp: []}, metaScheduleRelation: {...state.metaScheduleRelation, rsvp: meta}};
        case 'GRID_SCHEDULE_RELATION_STATUS_SUCCESS_RSVP':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, rsvp: false}, gridScheduleRelation: {...state.gridScheduleRelation, rsvp: action.payload.rsvp}, metaScheduleRelation: {...state.metaScheduleRelation, rsvp: action.payload.meta}};
        case 'GRID_SCHEDULE_RELATION_STATUS_FAILURE_RSVP':
            return {...state, gridScheduleRelationStatus: {...state.gridScheduleRelationStatus, rsvp: false}, gridScheduleRelation: {...state.gridScheduleRelation, rsvp: []}, metaScheduleRelation: {...state.metaScheduleRelation, rsvp: meta}};

        default:
            return state;
    }

}