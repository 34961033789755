const merchant = {
    id: null,
    name: null,
};
const meta = {
    page: 1,
    limit: 20,
    total: 0,
    totalPage: 0,
};

const initialState = {
    gridMerchantStatus: false,
    gridMerchant: [],
    metaMerchant: meta,
    viewMerchantStatus: false,
    viewMerchant: merchant,
    formMerchantProcess: false,
    formMerchantErrors: {},
    formMerchant: merchant,
    deleteMerchantProcess: false,
};

export default function MerchantReducer(state = initialState, action) {
    switch (action.type) {
        case 'GRID_MERCHANT_STATUS_PROCESS':
            return {...state, gridMerchantStatus: true, gridMerchant: initialState.gridMerchant, metaMerchant: meta};
        case 'GRID_MERCHANT_STATUS_SUCCESS':
            return {...state, gridMerchantStatus: false, gridMerchant: action.payload.merchants, metaMerchant: action.payload.meta};
        case 'GRID_MERCHANT_STATUS_FAILURE':
            return {...state, gridMerchantStatus: false, gridMerchant: initialState.gridMerchant, metaMerchant: meta};

        case 'VIEW_MERCHANT_STATUS_PROCESS':
            return {...state, viewMerchantStatus: true, viewMerchant: initialState.viewMerchant};
        case 'VIEW_MERCHANT_STATUS_SUCCESS':
            return {...state, viewMerchantStatus: false, viewMerchant: action.payload};
        case 'VIEW_MERCHANT_STATUS_FAILURE':
            return {...state, viewMerchantStatus: false, viewMerchant: initialState.viewMerchant};

        case 'FORM_MERCHANT_PROCESS':
            return {...state, formMerchantProcess: true, formMerchant: initialState.formMerchant};
        case 'FORM_MERCHANT_SUCCESS':
            return {...state, formMerchantProcess: false, formMerchant: action.payload};
        case 'FORM_MERCHANT_FAILURE':
            return {...state, formMerchantProcess: false, formMerchant: initialState.formMerchant};

        case 'DELETE_MERCHANT_PROCESS':
            return {...state, deleteMerchantProcess: true};
        case 'DELETE_MERCHANT_SUCCESS':
            return {...state, deleteMerchantProcess: false};
        case 'DELETE_MERCHANT_FAILURE':
            return {...state, deleteMerchantProcess: false};

        case 'VIEW_MERCHANT_RELATION_STATUS_PROCESS_MENU':
            return {...state, viewMerchantRelationStatus: {...state.viewMerchantRelationStatus, menu: true}, viewMerchantRelation: {...state.viewMerchantRelation, menu: {}}};
        case 'VIEW_MERCHANT_RELATION_STATUS_SUCCESS_MENU':
            return {...state, viewMerchantRelationStatus: {...state.viewMerchantRelationStatus, menu: false}, viewMerchantRelation: {...state.viewMerchantRelation, menu: action.payload}};
        case 'VIEW_MERCHANT_RELATION_STATUS_FAILURE_MENU':
            return {...state, viewMerchantRelationStatus: {...state.viewMerchantRelationStatus, menu: false}, viewMerchantRelation: {...state.viewMerchantRelation, menu: {}}};

        case 'FORM_MERCHANT_RELATION_PROCESS':
            return {...state, formMerchantRelationProcess: true, formMerchantRelationSection: action.relation};
        case 'FORM_MERCHANT_RELATION_SUCCESS':
            return {...state, formMerchantRelationProcess: false, formMerchantRelationSection: action.relation, formMerchantRelation: action.payload};
        case 'FORM_MERCHANT_RELATION_FAILURE':
            return {...state, formMerchantRelationProcess: false, formMerchantRelationSection: action.relation};

        case 'DELETE_MERCHANT_RELATION_PROCESS':
            return {...state, deleteMerchantRelationProcess: true};
        case 'DELETE_MERCHANT_RELATION_SUCCESS':
            return {...state, deleteMerchantRelationProcess: false};
        case 'DELETE_MERCHANT_RELATION_FAILURE':
            return {...state, deleteMerchantRelationProcess: false};

        case 'GRID_MERCHANT_RELATION_STATUS_PROCESS_BEVERAGE':
            return {...state, gridMerchantRelationStatus: {...state.gridMerchantRelationStatus, beverage: true}, gridMerchantRelation: {...state.gridMerchantRelation, beverage: []}, metaMerchantRelation: {...state.metaMerchantRelation, beverage: meta}};
        case 'GRID_MERCHANT_RELATION_STATUS_SUCCESS_BEVERAGE':
            return {...state, gridMerchantRelationStatus: {...state.gridMerchantRelationStatus, beverage: false}, gridMerchantRelation: {...state.gridMerchantRelation, beverage: action.payload.beverages}, metaMerchantRelation: {...state.metaMerchantRelation, beverage: action.payload.meta}};
        case 'GRID_MERCHANT_RELATION_STATUS_FAILURE_BEVERAGE':
            return {...state, gridMerchantRelationStatus: {...state.gridMerchantRelationStatus, beverage: false}, gridMerchantRelation: {...state.gridMerchantRelation, beverage: []}, metaMerchantRelation: {...state.metaMerchantRelation, beverage: meta}};
        case 'GRID_MERCHANT_RELATION_STATUS_PROCESS_SPECIAL':
            return {...state, gridMerchantRelationStatus: {...state.gridMerchantRelationStatus, special: true}, gridMerchantRelation: {...state.gridMerchantRelation, special: []}, metaMerchantRelation: {...state.metaMerchantRelation, special: meta}};
        case 'GRID_MERCHANT_RELATION_STATUS_SUCCESS_SPECIAL':
            return {...state, gridMerchantRelationStatus: {...state.gridMerchantRelationStatus, special: false}, gridMerchantRelation: {...state.gridMerchantRelation, special: action.payload.specialOrders}, metaMerchantRelation: {...state.metaMerchantRelation, special: action.payload.meta}};
        case 'GRID_MERCHANT_RELATION_STATUS_FAILURE_SPECIAL':
            return {...state, gridMerchantRelationStatus: {...state.gridMerchantRelationStatus, special: false}, gridMerchantRelation: {...state.gridMerchantRelation, special: []}, metaMerchantRelation: {...state.metaMerchantRelation, special: meta}};

        default:
            return state;
    }

}