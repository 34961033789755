const porseni = {
    id: null,
    title: null,
    startTime: null,
    endTime: null,
};
const meta = {
    page: 1,
    limit: 20,
    total: 0,
    totalPage: 0,
};

const initialState = {
    gridPorseniStatus: false,
    gridPorseni: [],
    metaPorseni: meta,
    viewPorseniStatus: false,
    viewPorseni: porseni,
    formPorseniProcess: false,
    formPorseniErrors: {},
    formPorseni: porseni,
    deletePorseniProcess: false,
};

export default function PorseniReducer(state = initialState, action) {
    switch (action.type) {
        case 'GRID_PORSENI_STATUS_PROCESS':
            return {...state, gridPorseniStatus: true, gridPorseni: initialState.gridPorseni, metaPorseni: meta};
        case 'GRID_PORSENI_STATUS_SUCCESS':
            return {...state, gridPorseniStatus: false, gridPorseni: action.payload.porsenis, metaPorseni: action.payload.meta};
        case 'GRID_PORSENI_STATUS_FAILURE':
            return {...state, gridPorseniStatus: false, gridPorseni: initialState.gridPorseni, metaPorseni: meta};

        case 'VIEW_PORSENI_STATUS_PROCESS':
            return {...state, viewPorseniStatus: true, viewPorseni: initialState.viewPorseni};
        case 'VIEW_PORSENI_STATUS_SUCCESS':
            return {...state, viewPorseniStatus: false, viewPorseni: action.payload};
        case 'VIEW_PORSENI_STATUS_FAILURE':
            return {...state, viewPorseniStatus: false, viewPorseni: initialState.viewPorseni};

        case 'FORM_PORSENI_PROCESS':
            return {...state, formPorseniProcess: true, formPorseni: initialState.formPorseni};
        case 'FORM_PORSENI_SUCCESS':
            return {...state, formPorseniProcess: false, formPorseni: action.payload};
        case 'FORM_PORSENI_FAILURE':
            return {...state, formPorseniProcess: false, formPorseni: initialState.formPorseni};

        case 'DELETE_PORSENI_PROCESS':
            return {...state, deletePorseniProcess: true};
        case 'DELETE_PORSENI_SUCCESS':
            return {...state, deletePorseniProcess: false};
        case 'DELETE_PORSENI_FAILURE':
            return {...state, deletePorseniProcess: false};

        case 'FORM_PORSENI_RELATION_PROCESS':
            return {...state, formPorseniRelationProcess: true, formPorseniRelationSection: action.relation};
        case 'FORM_PORSENI_RELATION_SUCCESS':
            return {...state, formPorseniRelationProcess: false, formPorseniRelationSection: action.relation, formPorseniRelation: action.payload};
        case 'FORM_PORSENI_RELATION_FAILURE':
            return {...state, formPorseniRelationProcess: false, formPorseniRelationSection: action.relation};

        case 'DELETE_PORSENI_RELATION_PROCESS':
            return {...state, deletePorseniRelationProcess: true};
        case 'DELETE_PORSENI_RELATION_SUCCESS':
            return {...state, deletePorseniRelationProcess: false};
        case 'DELETE_PORSENI_RELATION_FAILURE':
            return {...state, deletePorseniRelationProcess: false};

        case 'GRID_PORSENI_RELATION_STATUS_PROCESS_PIC':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, pic: true}, gridPorseniRelation: {...state.gridPorseniRelation, pic: []}, metaPorseniRelation: {...state.metaPorseniRelation, pic: meta}};
        case 'GRID_PORSENI_RELATION_STATUS_SUCCESS_PIC':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, pic: false}, gridPorseniRelation: {...state.gridPorseniRelation, pic: action.payload.pics}, metaPorseniRelation: {...state.metaPorseniRelation, pic: action.payload.meta}};
        case 'GRID_PORSENI_RELATION_STATUS_FAILURE_PIC':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, pic: false}, gridPorseniRelation: {...state.gridPorseniRelation, pic: []}, metaPorseniRelation: {...state.metaPorseniRelation, pic: meta}};

        case 'GRID_PORSENI_RELATION_STATUS_PROCESS_SCHEDULE':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, schedule: true}, gridPorseniRelation: {...state.gridPorseniRelation, schedule: []}, metaPorseniRelation: {...state.metaPorseniRelation, schedule: meta}};
        case 'GRID_PORSENI_RELATION_STATUS_SUCCESS_SCHEDULE':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, schedule: false}, gridPorseniRelation: {...state.gridPorseniRelation, schedule: action.payload.schedules}, metaPorseniRelation: {...state.metaPorseniRelation, schedule: action.payload.meta}};
        case 'GRID_PORSENI_RELATION_STATUS_FAILURE_SCHEDULE':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, schedule: false}, gridPorseniRelation: {...state.gridPorseniRelation, schedule: []}, metaPorseniRelation: {...state.metaPorseniRelation, schedule: meta}};

        case 'GRID_PORSENI_RELATION_STATUS_PROCESS_TEAM':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, team: true}, gridPorseniRelation: {...state.gridPorseniRelation, team: []}, metaPorseniRelation: {...state.metaPorseniRelation, team: meta}};
        case 'GRID_PORSENI_RELATION_STATUS_SUCCESS_TEAM':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, team: false}, gridPorseniRelation: {...state.gridPorseniRelation, team: action.payload.teams}, metaPorseniRelation: {...state.metaPorseniRelation, team: action.payload.meta}};
        case 'GRID_PORSENI_RELATION_STATUS_FAILURE_TEAM':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, team: false}, gridPorseniRelation: {...state.gridPorseniRelation, team: []}, metaPorseniRelation: {...state.metaPorseniRelation, team: meta}};

        case 'GRID_PORSENI_RELATION_STATUS_PROCESS_REGISTER':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, register: true}, gridPorseniRelation: {...state.gridPorseniRelation, register: []}, metaPorseniRelation: {...state.metaPorseniRelation, register: meta}};
        case 'GRID_PORSENI_RELATION_STATUS_SUCCESS_REGISTER':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, register: false}, gridPorseniRelation: {...state.gridPorseniRelation, register: action.payload.registers}, metaPorseniRelation: {...state.metaPorseniRelation, register: action.payload.meta}};
        case 'GRID_PORSENI_RELATION_STATUS_FAILURE_REGISTER':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, register: false}, gridPorseniRelation: {...state.gridPorseniRelation, register: []}, metaPorseniRelation: {...state.metaPorseniRelation, register: meta}};

        case 'GRID_PORSENI_RELATION_STATUS_PROCESS_RANK':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, rank: true}, gridPorseniRelation: {...state.gridPorseniRelation, rank: []}, metaPorseniRelation: {...state.metaPorseniRelation, rank: meta}};
        case 'GRID_PORSENI_RELATION_STATUS_SUCCESS_RANK':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, rank: false}, gridPorseniRelation: {...state.gridPorseniRelation, rank: action.payload.ranks}, metaPorseniRelation: {...state.metaPorseniRelation, rank: action.payload.meta}};
        case 'GRID_PORSENI_RELATION_STATUS_FAILURE_RANK':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, rank: false}, gridPorseniRelation: {...state.gridPorseniRelation, rank: []}, metaPorseniRelation: {...state.metaPorseniRelation, rank: meta}};

        case 'GRID_PORSENI_RELATION_STATUS_PROCESS_GALLERY':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, gallery: true}, gridPorseniRelation: {...state.gridPorseniRelation, gallery: []}, metaPorseniRelation: {...state.metaPorseniRelation, gallery: meta}};
        case 'GRID_PORSENI_RELATION_STATUS_SUCCESS_GALLERY':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, gallery: false}, gridPorseniRelation: {...state.gridPorseniRelation, gallery: action.payload.galleries}, metaPorseniRelation: {...state.metaPorseniRelation, gallery: action.payload.meta}};
        case 'GRID_PORSENI_RELATION_STATUS_FAILURE_GALLERY':
            return {...state, gridPorseniRelationStatus: {...state.gridPorseniRelationStatus, gallery: false}, gridPorseniRelation: {...state.gridPorseniRelation, gallery: []}, metaPorseniRelation: {...state.metaPorseniRelation, gallery: meta}};

        default:
            return state;
    }

}
