const livestream = {
    id: null,
    name: null,
    router: null,
    iconUrl: null,
    iconFile: null,
};

const meta = {
    page: 1,
    limit: 20,
    total: 0,
    totalPage: 0,
};

const initialState = {
    gridLivestreamStatus: false,
    gridLivestream: [],
    metaLivestream: meta,
    viewLivestreamStatus: false,
    viewLivestream: livestream,
    formLivestreamProcess: false,
    formLivestreamErrors: {},
    formLivestream: livestream,
    deleteLivestreamProcess: false,
};

export default function LivestreamReducer(state = initialState, action) {
    switch (action.type) {
        case 'GRID_LIVESTREAM_STATUS_PROCESS':
            return {...state, gridLivestreamStatus: true, gridLivestream: initialState.gridLivestream, metaLivestream: initialState.metaLivestream};
        case 'GRID_LIVESTREAM_STATUS_SUCCESS':
            return {...state, gridLivestreamStatus: false, gridLivestream: action.payload.livestreams, metaLivestream: action.payload.meta};
        case 'GRID_LIVESTREAM_STATUS_FAILURE':
            return {...state, gridLivestreamStatus: false, gridLivestream: initialState.gridLivestream, metaLivestream: initialState.metaLivestream};

        case 'VIEW_LIVESTREAM_STATUS_PROCESS':
            return {...state, viewLivestreamStatus: true, viewLivestream: initialState.viewLivestream};
        case 'VIEW_LIVESTREAM_STATUS_SUCCESS':
            return {...state, viewLivestreamStatus: false, viewLivestream: action.payload};
        case 'VIEW_LIVESTREAM_STATUS_FAILURE':
            return {...state, viewLivestreamStatus: false, viewLivestream: initialState.viewLivestream};

        case 'FORM_LIVESTREAM_PROCESS':
            return {...state, formLivestreamProcess: true, formLivestream: initialState.formLivestream};
        case 'FORM_LIVESTREAM_SUCCESS':
            return {...state, formLivestreamProcess: false, formLivestream: action.payload};
        case 'FORM_LIVESTREAM_FAILURE':
            return {...state, formLivestreamProcess: false, formLivestream: initialState.formLivestream};

        case 'DELETE_LIVESTREAM_PROCESS':
            return {...state, deleteLivestreamProcess: true};
        case 'DELETE_LIVESTREAM_SUCCESS':
            return {...state, deleteLivestreamProcess: false};
        case 'DELETE_LIVESTREAM_FAILURE':
            return {...state, deleteLivestreamProcess: false};

        default:
            return state;
    }

}
