const menu = {
    id: null,
    name: null,
    router: null,
    iconUrl: null,
    iconFile: null,
};
const menuTemplate = {
    id: null,
    name: null,
};

const meta = {
    page: 1,
    limit: 20,
    total: 0,
    totalPage: 0,
};

const initialState = {
    gridMenuStatus: false,
    gridMenu: [],
    metaMenu: meta,
    viewMenuStatus: false,
    viewMenu: menu,
    formMenuProcess: false,
    formMenuErrors: {},
    formMenu: menu,
    deleteMenuProcess: false,
    
    gridMenuTemplateStatus: false,
    gridMenuTemplate: [],
    metaMenuTemplate: meta,
    viewMenuTemplateStatus: false,
    viewMenuTemplate: menuTemplate,
    formMenuTemplateProcess: false,
    formMenuTemplateErrors: {},
    formMenuTemplate: menuTemplate,
    deleteMenuTemplateProcess: false,
};

export default function MenuReducer(state = initialState, action) {
    switch (action.type) {
        case 'GRID_MENU_STATUS_PROCESS':
            return {...state, gridMenuStatus: true, gridMenu: initialState.gridMenu, metaMenu: initialState.metaMenu};
        case 'GRID_MENU_STATUS_SUCCESS':
            return {...state, gridMenuStatus: false, gridMenu: action.payload.menus, metaMenu: action.payload.meta};
        case 'GRID_MENU_STATUS_FAILURE':
            return {...state, gridMenuStatus: false, gridMenu: initialState.gridMenu, metaMenu: initialState.metaMenu};

        case 'VIEW_MENU_STATUS_PROCESS':
            return {...state, viewMenuStatus: true, viewMenu: initialState.viewMenu};
        case 'VIEW_MENU_STATUS_SUCCESS':
            return {...state, viewMenuStatus: false, viewMenu: action.payload};
        case 'VIEW_MENU_STATUS_FAILURE':
            return {...state, viewMenuStatus: false, viewMenu: initialState.viewMenu};

        case 'FORM_MENU_PROCESS':
            return {...state, formMenuProcess: true, formMenu: initialState.formMenu};
        case 'FORM_MENU_SUCCESS':
            return {...state, formMenuProcess: false, formMenu: action.payload};
        case 'FORM_MENU_FAILURE':
            return {...state, formMenuProcess: false, formMenu: initialState.formMenu};

        case 'DELETE_MENU_PROCESS':
            return {...state, deleteMenuProcess: true};
        case 'DELETE_MENU_SUCCESS':
            return {...state, deleteMenuProcess: false};
        case 'DELETE_MENU_FAILURE':
            return {...state, deleteMenuProcess: false};
            
        case 'GRID_MENU_TEMPLATE_STATUS_PROCESS':
            return {...state, gridMenuTemplateStatus: true, gridMenuTemplate: initialState.gridMenuTemplate, metaMenuTemplate: initialState.metaMenuTemplate};
        case 'GRID_MENU_TEMPLATE_STATUS_SUCCESS':
            return {...state, gridMenuTemplateStatus: false, gridMenuTemplate: action.payload.templates, metaMenuTemplate: action.payload.meta};
        case 'GRID_MENU_TEMPLATE_STATUS_FAILURE':
            return {...state, gridMenuTemplateStatus: false, gridMenuTemplate: initialState.gridMenuTemplate, metaMenuTemplate: initialState.metaMenuTemplate};

        case 'VIEW_MENU_TEMPLATE_STATUS_PROCESS':
            return {...state, viewMenuTemplateStatus: true, viewMenuTemplate: initialState.viewMenuTemplate};
        case 'VIEW_MENU_TEMPLATE_STATUS_SUCCESS':
            return {...state, viewMenuTemplateStatus: false, viewMenuTemplate: action.payload};
        case 'VIEW_MENU_TEMPLATE_STATUS_FAILURE':
            return {...state, viewMenuTemplateStatus: false, viewMenuTemplate: initialState.viewMenuTemplate};

        case 'FORM_MENU_TEMPLATE_PROCESS':
            return {...state, formMenuTemplateProcess: true, formMenuTemplate: initialState.formMenuTemplate};
        case 'FORM_MENU_TEMPLATE_SUCCESS':
            return {...state, formMenuTemplateProcess: false, formMenuTemplate: action.payload};
        case 'FORM_MENU_TEMPLATE_FAILURE':
            return {...state, formMenuTemplateProcess: false, formMenuTemplate: initialState.formMenuTemplate};

        case 'DELETE_MENU_TEMPLATE_PROCESS':
            return {...state, deleteMenuTemplateProcess: true};
        case 'DELETE_MENU_TEMPLATE_SUCCESS':
            return {...state, deleteMenuTemplateProcess: false};
        case 'DELETE_MENU_TEMPLATE_FAILURE':
            return {...state, deleteMenuTemplateProcess: false};

        default:
            return state;
    }

}