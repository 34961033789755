const booth = {
    id: null,
    title: null,
    startTime: null,
    endTime: null,
};
const meta = {
    page: 1,
    limit: 20,
    total: 0,
    totalPage: 0,
};

const initialState = {
    gridBoothStatus: false,
    gridBooth: [],
    metaBooth: meta,
    viewBoothStatus: false,
    viewBooth: booth,
    formBoothProcess: false,
    formBoothErrors: {},
    formBooth: booth,
    deleteBoothProcess: false,
};

export default function BoothReducer(state = initialState, action) {
    switch (action.type) {
        case 'GRID_BOOTH_STATUS_PROCESS':
            return {...state, gridBoothStatus: true, gridBooth: initialState.gridBooth, metaBooth: meta};
        case 'GRID_BOOTH_STATUS_SUCCESS':
            return {...state, gridBoothStatus: false, gridBooth: action.payload.booths, metaBooth: action.payload.meta};
        case 'GRID_BOOTH_STATUS_FAILURE':
            return {...state, gridBoothStatus: false, gridBooth: initialState.gridBooth, metaBooth: meta};

        case 'VIEW_BOOTH_STATUS_PROCESS':
            return {...state, viewBoothStatus: true, viewBooth: initialState.viewBooth};
        case 'VIEW_BOOTH_STATUS_SUCCESS':
            return {...state, viewBoothStatus: false, viewBooth: action.payload};
        case 'VIEW_BOOTH_STATUS_FAILURE':
            return {...state, viewBoothStatus: false, viewBooth: initialState.viewBooth};

        case 'FORM_BOOTH_PROCESS':
            return {...state, formBoothProcess: true, formBooth: initialState.formBooth};
        case 'FORM_BOOTH_SUCCESS':
            return {...state, formBoothProcess: false, formBooth: action.payload};
        case 'FORM_BOOTH_FAILURE':
            return {...state, formBoothProcess: false, formBooth: initialState.formBooth};

        case 'DELETE_BOOTH_PROCESS':
            return {...state, deleteBoothProcess: true};
        case 'DELETE_BOOTH_SUCCESS':
            return {...state, deleteBoothProcess: false};
        case 'DELETE_BOOTH_FAILURE':
            return {...state, deleteBoothProcess: false};

        case 'VIEW_BOOTH_RELATION_STATUS_PROCESS_MENU':
            return {...state, viewBoothRelationStatus: {...state.viewBoothRelationStatus, menu: true}, viewBoothRelation: {...state.viewBoothRelation, menu: {}}};
        case 'VIEW_BOOTH_RELATION_STATUS_SUCCESS_MENU':
            return {...state, viewBoothRelationStatus: {...state.viewBoothRelationStatus, menu: false}, viewBoothRelation: {...state.viewBoothRelation, menu: action.payload}};
        case 'VIEW_BOOTH_RELATION_STATUS_FAILURE_MENU':
            return {...state, viewBoothRelationStatus: {...state.viewBoothRelationStatus, menu: false}, viewBoothRelation: {...state.viewBoothRelation, menu: {}}};

        case 'FORM_BOOTH_RELATION_PROCESS':
            return {...state, formBoothRelationProcess: true, formBoothRelationSection: action.relation};
        case 'FORM_BOOTH_RELATION_SUCCESS':
            return {...state, formBoothRelationProcess: false, formBoothRelationSection: action.relation, formBoothRelation: action.payload};
        case 'FORM_BOOTH_RELATION_FAILURE':
            return {...state, formBoothRelationProcess: false, formBoothRelationSection: action.relation};

        case 'DELETE_BOOTH_RELATION_PROCESS':
            return {...state, deleteBoothRelationProcess: true};
        case 'DELETE_BOOTH_RELATION_SUCCESS':
            return {...state, deleteBoothRelationProcess: false};
        case 'DELETE_BOOTH_RELATION_FAILURE':
            return {...state, deleteBoothRelationProcess: false};

        case 'GRID_BOOTH_RELATION_STATUS_PROCESS_ABSENT':
            return {...state, gridBoothRelationStatus: {...state.gridBoothRelationStatus, absent: true}, gridBoothRelation: {...state.gridBoothRelation, absent: []}, metaBoothRelation: {...state.metaBoothRelation, absent: meta}};
        case 'GRID_BOOTH_RELATION_STATUS_SUCCESS_ABSENT':
            return {...state, gridBoothRelationStatus: {...state.gridBoothRelationStatus, absent: false}, gridBoothRelation: {...state.gridBoothRelation, absent: action.payload.absents}, metaBoothRelation: {...state.metaBoothRelation, absent: action.payload.meta}};
        case 'GRID_BOOTH_RELATION_STATUS_FAILURE_ABSENT':
            return {...state, gridBoothRelationStatus: {...state.gridBoothRelationStatus, absent: false}, gridBoothRelation: {...state.gridBoothRelation, absent: []}, metaBoothRelation: {...state.metaBoothRelation, absent: meta}};
        case 'GRID_BOOTH_RELATION_STATUS_PROCESS_RATING':
            return {...state, gridBoothRelationStatus: {...state.gridBoothRelationStatus, rating: true}, gridBoothRelation: {...state.gridBoothRelation, rating: []}, metaBoothRelation: {...state.metaBoothRelation, rating: meta}};
        case 'GRID_BOOTH_RELATION_STATUS_SUCCESS_RATING':
            return {...state, gridBoothRelationStatus: {...state.gridBoothRelationStatus, rating: false}, gridBoothRelation: {...state.gridBoothRelation, rating: action.payload.ratings}, metaBoothRelation: {...state.metaBoothRelation, rating: action.payload.meta}};
        case 'GRID_BOOTH_RELATION_STATUS_FAILURE_RATING':
            return {...state, gridBoothRelationStatus: {...state.gridBoothRelationStatus, rating: false}, gridBoothRelation: {...state.gridBoothRelation, rating: []}, metaBoothRelation: {...state.metaBoothRelation, rating: meta}};
        case 'GRID_BOOTH_RELATION_STATUS_PROCESS_ACTIVITY':
            return {...state, gridBoothRelationStatus: {...state.gridBoothRelationStatus, activity: true}, gridBoothRelation: {...state.gridBoothRelation, activity: []}, metaBoothRelation: {...state.metaBoothRelation, activity: meta}};
        case 'GRID_BOOTH_RELATION_STATUS_SUCCESS_ACTIVITY':
            return {...state, gridBoothRelationStatus: {...state.gridBoothRelationStatus, activity: false}, gridBoothRelation: {...state.gridBoothRelation, activity: action.payload.activities}, metaBoothRelation: {...state.metaBoothRelation, activity: action.payload.meta}};
        case 'GRID_BOOTH_RELATION_STATUS_FAILURE_ACTIVITY':
            return {...state, gridBoothRelationStatus: {...state.gridBoothRelationStatus, activity: false}, gridBoothRelation: {...state.gridBoothRelation, activity: []}, metaBoothRelation: {...state.metaBoothRelation, activity: meta}};

        default:
            return state;
    }

}