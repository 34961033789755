const form = {
    id: null,
    title: null,
};
const question = {
    id: null,
    form: null,
};
const meta = {
    page: 1,
    limit: 20,
    total: 0,
    totalPage: 0,
};

const initialState = {
    gridFormStatus: false,
    gridForm: [],
    metaForm: meta,
    viewFormStatus: false,
    viewForm: form,
    customFormProcess: false,
    customFormErrors: {},
    customForm: form,
    deleteFormProcess: false,
    viewFormResponseStatus: false,
    viewFormResponse: [],
    gridFormQuestionStatus: false,
    gridFormQuestion: [],
    metaFormQuestion: meta,
    customFormQuestionProcess: false,
    customFormQuestionErrors: {},
    customFormQuestion: question,
    deleteFormQuestionProcess: false,
};

export default function FormReducer(state = initialState, action) {
    switch (action.type) {
        case 'GRID_FORM_STATUS_PROCESS':
            return {...state, gridFormStatus: true, gridForm: initialState.gridForm, metaForm: meta};
        case 'GRID_FORM_STATUS_SUCCESS':
            return {...state, gridFormStatus: false, gridForm: action.payload.forms, metaForm: action.payload.meta};
        case 'GRID_FORM_STATUS_FAILURE':
            return {...state, gridFormStatus: false, gridForm: initialState.gridForm, metaForm: meta};

        case 'VIEW_FORM_STATUS_PROCESS':
            return {...state, viewFormStatus: true, viewForm: initialState.viewForm};
        case 'VIEW_FORM_STATUS_SUCCESS':
            return {...state, viewFormStatus: false, viewForm: action.payload};
        case 'VIEW_FORM_STATUS_FAILURE':
            return {...state, viewFormStatus: false, viewForm: initialState.viewForm};

        case 'FORM_CUSTOM_PROCESS':
            return {...state, customFormProcess: true, customForm: initialState.customForm};
        case 'FORM_CUSTOM_SUCCESS':
            return {...state, customFormProcess: false, customForm: action.payload};
        case 'FORM_CUSTOM_FAILURE':
            return {...state, customFormProcess: false, customForm: initialState.customForm};

        case 'DELETE_FORM_PROCESS':
            return {...state, deleteFormProcess: true};
        case 'DELETE_FORM_SUCCESS':
            return {...state, deleteFormProcess: false};
        case 'DELETE_FORM_FAILURE':
            return {...state, deleteFormProcess: false};

        case 'VIEW_FORM_RESPONSE_STATUS_PROCESS':
            return {...state, viewFormResponseStatus: true, viewFormResponse: initialState.viewFormResponse};
        case 'VIEW_FORM_RESPONSE_STATUS_SUCCESS':
            return {...state, viewFormResponseStatus: false, viewFormResponse: action.payload.responses};
        case 'VIEW_FORM_RESPONSE_STATUS_FAILURE':
            return {...state, viewFormResponseStatus: false, viewFormResponse: initialState.viewFormResponse};

        case 'GRID_FORM_QUESTION_STATUS_PROCESS':
            return {...state, gridFormQuestionStatus: true, gridFormQuestion: initialState.gridForm, metaFormQuestion: meta};
        case 'GRID_FORM_QUESTION_STATUS_SUCCESS':
            return {...state, gridFormQuestionStatus: false, gridFormQuestion: action.payload.questions, metaFormQuestion: action.payload.meta};
        case 'GRID_FORM_QUESTION_STATUS_FAILURE':
            return {...state, gridFormQuestionStatus: false, gridFormQuestion: initialState.gridForm, metaFormQuestion: meta};

        case 'FORM_CUSTOM_QUESTION_PROCESS':
            return {...state, customFormQuestionProcess: true, customFormQuestion: initialState.customForm};
        case 'FORM_CUSTOM_QUESTION_SUCCESS':
            return {...state, customFormQuestionProcess: false, customFormQuestion: action.payload};
        case 'FORM_CUSTOM_QUESTION_FAILURE':
            return {...state, customFormQuestionProcess: false, customFormQuestion: initialState.customForm};

        case 'DELETE_FORM_QUESTION_PROCESS':
            return {...state, deleteFormQuestionProcess: true};
        case 'DELETE_FORM_QUESTION_SUCCESS':
            return {...state, deleteFormQuestionProcess: false};
        case 'DELETE_FORM_QUESTION_FAILURE':
            return {...state, deleteFormQuestionProcess: false};

        default:
            return state;
    }

}