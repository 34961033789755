import React from 'react';
import ReactDOM from 'react-dom';
import {library} from '@fortawesome/fontawesome-svg-core'
import {fab} from '@fortawesome/free-brands-svg-icons'
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/styles/variables.scss';
import './components/styles/sizing.scss';
import './components/styles/spacing.scss';
import './components/styles/fonts.scss';
import './components/styles/custom.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-popup/style.css';
import 'react-placeholder/lib/reactPlaceholder.css';
import 'react-table/react-table.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import {ENV} from './config/app';
import App from "./App";
import * as serviceWorker from './serviceWorker';

library.add(fab);

if (process.env.REACT_APP_ENV === 'prod') {
    Sentry.init({
        dsn: "https://f4e246dca329411a9b640e6b19565c82@o250660.ingest.sentry.io/6103762",
        integrations: [new Integrations.BrowserTracing()],
      
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });      
}

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (ENV === 'prod') {
    // serviceWorker.register();
    serviceWorker.unregister();
} else {
    serviceWorker.unregister();
}
