const initialState = {
    formRsvpProcess: false,
    formRsvpErrors: {},
    formRsvp: {
        id: null,
        event: null,
        user: null,
    },
    deleteRsvpProcess: false,
};

export default function RsvpReducer(state = initialState, action) {
    switch (action.type) {
        case 'FORM_RSVP_PROCESS':
            return {...state, formRsvpProcess: true};
        case 'FORM_RSVP_SUCCESS':
            return {...state, formRsvpProcess: false, success: true, formRsvp: action.payload.rsvp};
        case 'FORM_RSVP_FAILURE':
            return {...state, formRsvpProcess: false};

        case 'DELETE_RSVP_PROCESS':
            return {...state, deleteRsvpProcess: true};
        case 'DELETE_RSVP_SUCCESS':
            return {...state, deleteRsvpProcess: false};
        case 'DELETE_RSVP_FAILURE':
            return {...state, deleteRsvpProcess: false};

        default:
            return state;
    }

}