import {reducer as formReducer} from 'redux-form';
import AuthReducer from "../components/auth/AuthReducer";
import DashboardReducer from "../containers/dashboard/DashboardReducer";
import LogReducer from "../containers/dashboard/logs/LogReducer";
import EventReducer from "../containers/dashboard/event/EventReducer";
import UserReducer from "../containers/dashboard/user/UserReducer";
import RsvpReducer from "../containers/dashboard/rsvp/RsvpReducer";
import MenuReducer from "../containers/dashboard/menu/MenuReducer";
import QuizReducer from "../containers/dashboard/quiz/QuizReducer";
import BoothReducer from "../containers/dashboard/booth/BoothReducer";
import FormReducer from "../containers/dashboard/form/FormReducer";
import ScheduleReducer from "../containers/dashboard/schedule/ScheduleReducer";
import MerchantReducer from "../containers/dashboard/merchant/MerchantReducer";
import CommonReducer from "../components/common/CommonReducer";
import PorseniReducer from "../containers/dashboard/porseni/PorseniReducer";
import NewsReducer from "../containers/dashboard/news/NewsReducer";
import LivestreamReducer from "../containers/dashboard/livestream/LivestreamReducer";

export default {
    AuthReducer,
    CommonReducer,
    DashboardReducer,
    LogReducer,
    UserReducer,
    EventReducer,
    RsvpReducer,
    MenuReducer,
    QuizReducer,
    BoothReducer,
    FormReducer,
    ScheduleReducer,
    MerchantReducer,
    PorseniReducer,
    NewsReducer,
    LivestreamReducer,
    form: formReducer
}
