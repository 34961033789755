const quiz = {
    id: null,
    title: null,
    startTime: null,
    endTime: null,
};
const meta = {
    page: 1,
    limit: 20,
    total: 0,
    totalPage: 0,
};

const initialState = {
    gridQuizStatus: false,
    gridQuiz: [],
    metaQuiz: meta,
    viewQuizStatus: false,
    viewQuiz: quiz,
    formQuizProcess: false,
    formQuizErrors: {},
    formQuiz: quiz,
    deleteQuizProcess: false,
};

export default function QuizReducer(state = initialState, action) {
    switch (action.type) {
        case 'GRID_QUIZ_STATUS_PROCESS':
            return {...state, gridQuizStatus: true, gridQuiz: initialState.gridQuiz, metaQuiz: meta};
        case 'GRID_QUIZ_STATUS_SUCCESS':
            return {...state, gridQuizStatus: false, gridQuiz: action.payload.quizzes, metaQuiz: action.payload.meta};
        case 'GRID_QUIZ_STATUS_FAILURE':
            return {...state, gridQuizStatus: false, gridQuiz: initialState.gridQuiz, metaQuiz: meta};

        case 'VIEW_QUIZ_STATUS_PROCESS':
            return {...state, viewQuizStatus: true, viewQuiz: initialState.viewQuiz};
        case 'VIEW_QUIZ_STATUS_SUCCESS':
            return {...state, viewQuizStatus: false, viewQuiz: action.payload};
        case 'VIEW_QUIZ_STATUS_FAILURE':
            return {...state, viewQuizStatus: false, viewQuiz: initialState.viewQuiz};

        case 'FORM_QUIZ_PROCESS':
            return {...state, formQuizProcess: true, formQuiz: initialState.formQuiz};
        case 'FORM_QUIZ_SUCCESS':
            return {...state, formQuizProcess: false, formQuiz: action.payload};
        case 'FORM_QUIZ_FAILURE':
            return {...state, formQuizProcess: false, formQuiz: initialState.formQuiz};

        case 'DELETE_QUIZ_PROCESS':
            return {...state, deleteQuizProcess: true};
        case 'DELETE_QUIZ_SUCCESS':
            return {...state, deleteQuizProcess: false};
        case 'DELETE_QUIZ_FAILURE':
            return {...state, deleteQuizProcess: false};

        default:
            return state;
    }

}