export const ENV = 'prod';
export const BACKEND_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
    ? process.env.REACT_APP_API_ENDPOINT
    : 'https://myinternalevents.com';
export const PAGE_LOGIN = '/login';
export const PAGE_DASHBOARD = '/dashboard/statistic';
export const PAGE_MERCHANT = '/dashboard/merchant';

export const ROLE_USER = 'ROLE_USER';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_MERCHANT = 'ROLE_MERCHANT';
export const ROLE_PORSENI = 'ROLE_PORSENI';
export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const USER_ROLES = [ROLE_USER, ROLE_MERCHANT, ROLE_PORSENI, ROLE_ADMIN, ROLE_SUPER_ADMIN];
export const ADMIN_USER_ROLES = [ROLE_USER];

export const GENDER_PRIA = 'Pria';
export const GENDER_WANITA = 'Wanita';
export const USER_GENDERS = [GENDER_PRIA, GENDER_WANITA];

export const EMPLOYEE_STATUS_KARTAP = 'Karyawan Tetap';
export const EMPLOYEE_STATUS_NON_KARTAP = 'Karyawan Tidak Tetap';
export const USER_EMPLOYEE_STATUSES = [EMPLOYEE_STATUS_KARTAP, EMPLOYEE_STATUS_NON_KARTAP];

export const ACCESS_LEVEL_BRONZE = 'BRONZE';
export const ACCESS_LEVEL_SILVER = 'SILVER';
export const ACCESS_LEVEL_GOLD = 'GOLD';
export const ACCESS_LEVEL_PLATINUM = 'PLATINUM';
export const ACCESS_LEVELS = [
    '', ACCESS_LEVEL_BRONZE, ACCESS_LEVEL_SILVER,
    ACCESS_LEVEL_GOLD, ACCESS_LEVEL_PLATINUM
];

export const BANNER_SPLASH = 1;
export const BANNER_MENU_TOP = 2;
export const BANNER_MENU_BOTTOM = 3;
export const BANNER_POSITIONS = [
    {name: 'BANNER_SPLASH', value: BANNER_SPLASH},
    {name: 'BANNER_MENU_TOP', value: BANNER_MENU_TOP},
    // {name: 'BANNER_MENU_BOTTOM', value: BANNER_MENU_BOTTOM},
];

export const INPUT_TYPE_TEXT = 1;
export const INPUT_TYPE_TEXTAREA = 2;
export const INPUT_TYPE_SELECT = 3;
export const INPUT_TYPE_RADIO = 4;
export const INPUT_TYPE_CHECK = 5;
export const INPUT_TYPE_DATETIME = 6;
export const INPUT_TYPE_DATE = 7;
export const INPUT_TYPE_TIME = 8;
export const INPUT_TYPES = [
    {name: 'INPUT_TYPE_TEXT', value: INPUT_TYPE_TEXT},
    {name: 'INPUT_TYPE_TEXTAREA', value: INPUT_TYPE_TEXTAREA},
    {name: 'INPUT_TYPE_SELECT', value: INPUT_TYPE_SELECT},
    {name: 'INPUT_TYPE_RADIO', value: INPUT_TYPE_RADIO},
    {name: 'INPUT_TYPE_CHECK', value: INPUT_TYPE_CHECK},
    {name: 'INPUT_TYPE_DATETIME', value: INPUT_TYPE_DATETIME},
    {name: 'INPUT_TYPE_DATE', value: INPUT_TYPE_DATE},
    {name: 'INPUT_TYPE_TIME', value: INPUT_TYPE_TIME},
];
