const initialState = {
    updateIsActiveStatus: false
};

export default function CommonReducer(state = initialState, action) {
    switch (action.type) {
        case 'UPDATE_IS_ACTIVE_PROCESS':
            return {...state, updateIsActiveStatus: true};
        case 'UPDATE_IS_ACTIVE_SUCCESS':
            return {...state, updateIsActiveStatus: false};
        case 'UPDATE_IS_ACTIVE_FAILED':
            return {...state, updateIsActiveStatus: false};

        default:
            return state;
    }

}