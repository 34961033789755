const news = {
    id: null,
    name: null,
    router: null,
    iconUrl: null,
    iconFile: null,
};

const meta = {
    page: 1,
    limit: 20,
    total: 0,
    totalPage: 0,
};

const initialState = {
    gridNewsStatus: false,
    gridNews: [],
    metaNews: meta,
    viewNewsStatus: false,
    viewNews: news,
    formNewsProcess: false,
    formNewsErrors: {},
    formNews: news,
    deleteNewsProcess: false,
};

export default function NewsReducer(state = initialState, action) {
    switch (action.type) {
        case 'GRID_NEWS_STATUS_PROCESS':
            return {...state, gridNewsStatus: true, gridNews: initialState.gridNews, metaNews: initialState.metaNews};
        case 'GRID_NEWS_STATUS_SUCCESS':
            return {...state, gridNewsStatus: false, gridNews: action.payload.news, metaNews: action.payload.meta};
        case 'GRID_NEWS_STATUS_FAILURE':
            return {...state, gridNewsStatus: false, gridNews: initialState.gridNews, metaNews: initialState.metaNews};

        case 'VIEW_NEWS_STATUS_PROCESS':
            return {...state, viewNewsStatus: true, viewNews: initialState.viewNews};
        case 'VIEW_NEWS_STATUS_SUCCESS':
            return {...state, viewNewsStatus: false, viewNews: action.payload};
        case 'VIEW_NEWS_STATUS_FAILURE':
            return {...state, viewNewsStatus: false, viewNews: initialState.viewNews};

        case 'FORM_NEWS_PROCESS':
            return {...state, formNewsProcess: true, formNews: initialState.formNews};
        case 'FORM_NEWS_SUCCESS':
            return {...state, formNewsProcess: false, formNews: action.payload};
        case 'FORM_NEWS_FAILURE':
            return {...state, formNewsProcess: false, formNews: initialState.formNews};

        case 'DELETE_NEWS_PROCESS':
            return {...state, deleteNewsProcess: true};
        case 'DELETE_NEWS_SUCCESS':
            return {...state, deleteNewsProcess: false};
        case 'DELETE_NEWS_FAILURE':
            return {...state, deleteNewsProcess: false};

        default:
            return state;
    }

}
